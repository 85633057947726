<template>
  <div id="contactUs">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="contactUsCon clearfix">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p class="animated bounceInLeft" v-if="contactUsData.title&&contactUsData.title.length>0" v-html="contactUsData.title[0].title"></p>
          <p class="animated bounceInLeft" style="padding-top:10px;"><a href="mailto:hr@vivo-light.com">Join Us</a></p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>
      <!--公司介绍-->
      <div class="contactBox">
        <ul class="contactInfo" v-show="addressData&&addressData.length>0">
          <li class="contactItem" v-for="item in addressData" :key="item.id">
            <div class="cont_img clearfix">
              <img v-lazy="item.main_image" alt="公司图片">
            </div>
            <div class="cont_title">{{item.title}}</div>
            <div class="cont_num" v-if="item.type_data==1">
              <div class="cont_num_item">
                <div>TEL</div>
                <div>{{item.tel}}</div>
              </div>
              <div class="cont_num_item">
                <div>HOTLINE</div>
                <div>{{item.technical_support}}</div>
              </div>
            </div>
            <div class="cont_num" v-else>
              <div class="cont_num_item">
                <div>TEL</div>
                <div>{{item.tel}}</div>
              </div>
            </div>
            <div class="add">
              <span>ADD</span>
              <span>{{item.address}}</span>
            </div>
            <div class="add_emai">
              <span>MAIL</span>
              <span>{{item.email}}</span>
            </div>
            <div class="add_web">
              <span>WEB</span>
              <span>{{item.web_site}}</span>
            </div>
          </li>
        </ul>
        <div class="contentUsLink">
            <a href="https://twitter.com/Vivolight666" target="_blank"><img src="../assets/images/link_icon_3.png"></a>
            <a href="https://www.instagram.com/vivolight666/" target="_blank"><img src="../assets/images/link_icon_1.png"></a>
            <a href="https://www.facebook.com/profile.php?id=100083844817137" target="_blank"><img src="../assets/images/link_icon_4.png"></a>
            <a href="https://www.youtube.com/channel/UCpTIXDZ95jstQ_rqudjdvnw" target="_blank"><img src="../assets/images/link_icon_2.png"></a>
            <a href="https://www.linkedin.com/company/vivolight/?viewAsMember=true" target="_blank"><img src="../assets/images/link_icon_5.png"></a>
        </div>
      </div>
      <Copyright></Copyright>
    </div>

  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetIndexDoc,GetAddressData} from "@/api/api";
export default {
  data(){
    return{
      //联系我们banner数据
      contactUsData:{},
      //地址信息
      addressData:{}

    }
  },
  mounted() {
    //获取联系我们页面banner数据
    this.toGetIndexDoc(10)
    this.toGetAddressData()
  },
  methods:{
    toGetIndexDoc(data){
      let _this=this
        GetIndexDoc(data)
            .then(r=>{
              let res=r.data
              if(res.status!=1){
                _this.contactUsData=[]
                return
              }
              let apiIndex=res.data
              _this.contactUsData=apiIndex
            })
    },
    toGetAddressData(){
      let _this=this
      GetAddressData()
            .then(r=>{
              let res=r.data
              if(res.status!=1){
                _this.addressData=[]
                return
              }
              let apiIndex=res.data
              _this.addressData=apiIndex
            })
    }
  },
  components: {
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
#contactUs {
  background-color: #071D42;
}
.contactUsCon {
  padding-top: 13.888vw;
  background-color: #071D42;
  width: 100%;

  .commenHead {
    background: url("../assets/images/pic_banner_contact.png") no-repeat center -2.777vw;
    background-size: 180%;
  }

  //联系我们信息
  .contactBox{
    padding:0 6.666vw 16.666vw;
    margin-top:-5.555vw;
    background: #071D42;
    .contactInfo{
      width: 100%;
      .contactItem{
        width: 100%;
        background: rgba(32, 191, 208, 0.1);
        border-radius: 1.1111vw;
        padding:4.444vw;
        margin-bottom:3.333vw;
        box-sizing: border-box;
        .cont_img{
          width: 100%;
          height: 48.05vw;
          margin-bottom: 3.333vw;
          img{
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
        .cont_title{
          width: 100%;
          margin-bottom:4.444vw;
          color:#20BFD0;
          font-weight: 400;
          font-size: 3.888vw;
          font-family: Montserrat-Regular, Montserrat;
          line-height: 4.722vw;
        }
        .cont_num{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .cont_num_item{
            margin-bottom:5.555vw;
            div:first-child{
              font-size: 3.333vw;
              line-height: 4.444vw;
              font-weight: 500;
              color: #20BFD0;
              margin-bottom:1.388vw;
              font-family: Montserrat-Medium, Montserrat;
            }
            div:last-child{
              font-size: 3.888vw;
              line-height: 4.444vw;
              font-weight: 400;
              font-family: Montserrat;
              color: rgba(255, 255, 255, .9);
            }
          }

        }
        .add,
        .add_emai,
        .add_web,
        .cont_num{
          width: 100%;
          display: flex;
          margin-bottom:2.2222vw;
          //text-align: justify;
          span:first-child{
            width: 15%;
            // font-size: 2.777vw;
            font-size: 3.333vw;
            line-height: 4.444vw;
            font-weight: 500;
            color: #20BFD0;
            font-family: Montserrat-Medium, Montserrat;
          }
          span:last-child{
            width: 85%;
            // font-size: 2.777vw;
            font-size: 3.333vw;
            line-height: 4.444vw;
            font-weight: 300;
            font-family: Montserrat;
            color: rgba(255, 255, 255, .9);
          }

        }
      }
    }
  }
}
</style>